<template>
  <div>
    <form
      class="sm:w-11/12 w-full px-4 lg:px-0 mx-auto"
      @submit="submitPaymentForm"
      autocomplete="off"
      ref="form"
    >

      <div class="flex">
        <div class="w-1/3" v-for="(instrument, key) in campaign.gamecash.instruments" :key="key">
          <payment-instrument
            :code="instrument"
            :isActive="form.instrument == instrument"
            @selected="form.instrument = $event"
          />
        </div>
      </div>

      <div class="pb-2 pt-4">
        <select v-model="form.amount" id="amount" class="block w-full p-4 text-lg rounded-sm bg-black">
          <option value="">- Monto -</option>
          <option v-for="(amount, key) in campaign.amounts" :key="key" :value="amount">{{ currency(amount) }}</option>
        </select>
        <p v-for="(error, key) in errors.amount" :key="key" class="mt-1 text-red-500 text-xs italic">{{ error }}</p>
      </div>

      <div class="pb-2 pt-4">
        <div class="flex">
          <div class="w-1/2 mr-3">
            <input type="text" v-model.trim="form.firstname" id="firstname" placeholder="Nombre" class="w-full p-4 text-lg rounded-sm bg-black" />
            <p v-for="(error, key) in errors.firstname" :key="key" class="mt-1 text-red-500 text-xs italic">{{ error }}</p>
          </div>

          <div class="w-1/2 ml-3">
            <input type="text" v-model.trim="form.lastname" id="lastname" placeholder="Apellidos" class="w-full p-4 text-lg rounded-sm bg-black" />
            <p v-for="(error, key) in errors.lastname" :key="key" class="mt-1 text-red-500 text-xs italic">{{ error }}</p>
          </div>
        </div>
      </div>

      <div class="pb-2 pt-4">
        <input type="email" v-model="form.email" id="email" placeholder="Email" class="block w-full p-4 text-lg rounded-sm bg-black" />
        <p v-for="(error, key) in errors.email" :key="key" class="mt-1 text-red-500 text-xs italic">{{ error }}</p>
      </div>

      <div class="px-4 pb-2 pt-4">
        <progress-button
          class="uppercase block w-2/3 p-4 mx-auto text-lg rounded-lg bg-red-500 hover:bg-red-600 focus:outline-none"
          @click.native="submitPaymentForm"
          :disabled="isWorking"
          :processing="isWorking"
        >
          Generar boleta
        </progress-button>
      </div>

      <div class="p-4 text-center right-0 left-0 flex justify-center space-x-4 mt-16 lg:hidden">
        <social-network
          v-for="(username, social_network) in campaign.socialmedia"
          :key="social_network"
          :username="username"
          :social_network="social_network" />
      </div>
      <!--/ Social Media -->

    </form>
  </div>
</template>

<script>
import SocialNetwork from '@/views/templates/basic/components/SocialNetwork'
import ProgressButton from '@/views/templates/basic/components/ProgressButton'
import PaymentInstrument from '@/components/PaymentMethods/PaymentInstrument'

export default {
  name: 'FormPayment',

  components: {
    SocialNetwork,
    ProgressButton,
    PaymentInstrument,
  },

  data: () => ({
    form: {
      instrument: '',
      amount: '',
      firstname: '',
      lastname: '',
      email: '',
    },
    errors: [],

    isWorking: false,
  }),

  mounted () {
    this.form.instrument = this.campaign.gamecash.instruments[0]
  },

  methods: {
    submitPaymentForm (e) {
      e.preventDefault()

      this.errors = []
      this.isWorking = true

      if (this.$refs.form.reportValidity) {
        const payload = {
          instrument: this.form.instrument,
          amount: this.form.amount,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
        }

        this.$store.dispatch('generateOrder', payload)
          .then(order => {
            return this.$router.push({
              name: 'ReceiptFacade',
              params: {
                campaign: this.$store.state.campaign.id,
                order: order.id,
              },
            })
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.isWorking = false
          })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
