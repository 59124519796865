<template>
  <layout>

    <payment-form />
    <!--/ Payment Form  -->

  </layout>
</template>

<script>
import Layout from '@/views/templates/basic/Layout'
import PaymentForm from '@/views/templates/basic/components/PaymentForm'
import MetasMixin from '@/mixins/meta'

export default {
  name: 'Home',

  mixins: [ MetasMixin ],

  components: {
    Layout,
    PaymentForm,
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
