<template>
  <button
    class="flex items-center relative"
    type="button"
    ref="button"
  >
    <span class="block w-full" :class="{ invisible: processing }"> <slot /> </span>

    <span
      v-if="processing"
      class="absolute"
      style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
    >
      <loader class="text-white" width="64" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    focus () {
      this.$refs.button.focus()
    },
  },
}
</script>
