<template>
  <button
    type="button"
    class="w-full p-2 mb-2 uppercase text-center text-white font-mono font-semibold text-lg focus:outline-none"
    :class="[ isActive ? 'border-b-2' : 'opacity-40' ]"
    @click="$emit('selected', code)"
  >
    {{ code }}
  </button>
</template>

<script>
export default {
  name: 'PaymentInstrument',

  props: {
    code: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
